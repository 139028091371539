<template>
  <div class="w-full bg-white py-[10px]">
    <div v-if="step === 1">
      <div class="w-full lg:w-[80%] mx-auto">
        <div class="pt-[20px]">
          <h5 class="text-[20px] font-semibold">
            {{ $t("git.import_settings") }}
          </h5>
        </div>
        <div class="py-[15px]">
          <p class="text-[15px]">{{ $t("git.import_setting_description") }}</p>
        </div>
        <div class="flex justify-center py-[30px] gap-3">
          <b-button
            v-if="programType === 'fortnox'"
            variant="primary"
            v-on:click="onSet('contract')"
            class="mr-[10px]"
            size="md"
            v-b-tooltip.hover
            :title="$t('git.contract_tooltip')"
          >
            <span v-text="$t('git.as_contracts')" />
          </b-button>
          <b-button
            v-if="programType === 'fortnox'"
            variant="primary"
            v-on:click="onSet('split_invoices')"
            class="mr-[10px]"
            size="md"
            v-b-tooltip.hover
            :title="$t('git.split_invoices_tooltip')"
          >
            <span v-text="$t('git.as_split_invoices')" />
          </b-button>
          <b-button
            variant="primary"
            v-on:click="onSet('invoice')"
            size="md"
            v-b-tooltip.hover
            :title="$t('git.invoice_tooltip')"
          >
            <span v-text="$t('git.as_invoices')" />
          </b-button>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="import_data_as === 'contract'">
        <div class="py-[10px]">
          <h5 class="text-[20px] font-semibold">
            {{ $t("git.import_contract_settings") }}
          </h5>
        </div>
        <div class="block lg:flex">
          <div class="w-full lg:w-[50%]">
            <div
              class="w-full lg:w-[80%] py-[10px]"
              v-if="!hidden_fields.contract_length"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.contract_length")
              }}</label>
              <b-form-input
                type="number"
                v-model="payload.contract_length"
                class="w-[40%]"
                :placeholder="$t('git.contract_length')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.invoice_interval"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_interval")
              }}</label>
              <b-form-input
                type="number"
                v-model="payload.invoice_interval"
                class="w-[40%]"
                :placeholder="$t('git.invoice_interval')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.contract_date"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.contract_date")
              }}</label>

              <date-picker
                valueType="format"
                class="w-100"
                v-model="payload.contract_date"
                prefix-class="xmx"
                :placeholder="$t(`git.contract_date`)"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.period_start"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.period_start")
              }}</label>
              <date-picker
                valueType="format"
                class="w-100"
                v-model="payload.period_start"
                prefix-class="xmx"
                :placeholder="$t(`git.period_start`)"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.your_reference"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.your_reference")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.your_reference"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.your_reference')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.our_reference"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.our_reference")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.our_reference"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.our_reference')"
              />
            </div>
          </div>
          <div class="w-full lg:w-[50%]">
            <div
              class="w-full lg:w-[90%] py-[10px]"
              v-if="!hidden_fields.invoice_text"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_text")
              }}</label>
              <b-form-textarea
                id="textarea"
                v-model="payload.invoice_text"
                :placeholder="$t('git.invoice_text')"
                rows="4"
                cols="7"
                max-rows="7"
              ></b-form-textarea>
            </div>
            <div
              class="w-full lg:w-[90%] pb-[10px]"
              v-if="!hidden_fields.extra_invoice_row_text"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.extra_invoice_row_text")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.extra_invoice_row_text"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.extra_invoice_row_text')"
              />
            </div>
            <div
              class="w-full lg:w-[90%] pb-[10px]"
              v-if="!hidden_fields.terms_of_payment"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.payment_terms")
              }}</label>
              <v-select
                label="description"
                :placeholder="$t(`git.payment_terms`)"
                :options="termsOfPayment"
                v-model="selectedTop"
                @option:selected="computeSelect($event)"
                class="resource-selector select-size-sm d-inline-block w-full"
              >
              </v-select>
              <div
                class="w-full lg:w-[80%] pb-[10px]"
                v-if="!hidden_fields.divide_amount_on_contract_length"
              >
                <label class="text-[15px] font-semibold">{{
                  $t("git.divide_amount_on_contract_length")
                }}</label>
                <b-form-checkbox
                  v-model="payload.divide_amount_on_contract_length"
                  class="custom-control-primary my-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="import_data_as === 'split_invoices'">
        <div class="py-[10px]">
          <h5 class="text-[20px] font-semibold">
            {{ $t("git.import_split_invoices_settings") }}
          </h5>
        </div>
        <div class="block lg:flex">
          <div class="w-full lg:w-[50%]">
            <div
              class="w-full lg:w-[80%] py-[10px]"
              v-if="!hidden_fields.number_of_invoices_to_create"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.number_of_invoices_to_create")
              }}</label>
              <b-form-input
                type="number"
                v-model="payload.number_of_invoices_to_create"
                class="w-[40%]"
                :placeholder="$t('git.number_of_invoices_to_create')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.invoice_interval"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_interval")
              }}</label>
              <b-form-input
                type="number"
                v-model="payload.invoice_interval"
                class="w-[40%]"
                :placeholder="$t('git.invoice_interval')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.invoice_date"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.initial_invoice_date")
              }}</label>
              <date-picker
                valueType="format"
                class="w-100"
                v-model="payload.invoice_date"
                prefix-class="xmx"
                :placeholder="$t(`git.initial_invoice_date`)"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.your_reference"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.your_reference")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.your_reference"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.your_reference')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.our_reference"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.our_reference")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.our_reference"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.our_reference')"
              />
            </div>
          </div>
          <div class="w-full lg:w-[50%]">
            <div
              class="w-full lg:w-[90%] py-[10px]"
              v-if="!hidden_fields.invoice_text"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_text")
              }}</label>
              <b-form-textarea
                id="textarea"
                v-model="payload.invoice_text"
                :placeholder="$t('git.invoice_text')"
                rows="4"
                cols="7"
                max-rows="7"
              ></b-form-textarea>
            </div>
            <div
              class="w-full lg:w-[90%] pb-[10px]"
              v-if="!hidden_fields.extra_invoice_row_text"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.extra_invoice_row_text")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.extra_invoice_row_text"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.extra_invoice_row_text')"
              />
            </div>
            <div
              class="w-full lg:w-[90%] pb-[10px]"
              v-if="!hidden_fields.terms_of_payment"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.payment_terms")
              }}</label>
              <v-select
                label="description"
                :placeholder="$t(`git.payment_terms`)"
                :options="termsOfPayment"
                v-model="selectedTop"
                @option:selected="computeSelect($event)"
                class="resource-selector select-size-sm d-inline-block w-full"
              >
              </v-select>
              <div
                class="w-full lg:w-[80%] pb-[10px]"
                v-if="!hidden_fields.divide_amount_on_number_of_invoices"
              >
                <label class="text-[15px] font-semibold">{{
                  $t("git.divide_amount_on_number_of_invoices")
                }}</label>
                <b-form-checkbox
                  v-model="payload.divide_amount_on_number_of_invoices"
                  class="custom-control-primary my-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="import_data_as === 'invoice'">
        <div class="py-[10px]">
          <h5 class="text-[20px] font-semibold">
            {{ $t("git.import_settings") }}
          </h5>
        </div>
        <div class="block lg:flex">
          <div class="w-full lg:w-[50%]">
            <div
              class="w-full lg:w-[80%] py-[10px]"
              v-if="!hidden_fields.invoice_fee"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_fee")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.invoice_fee"
                class="w-[40%]"
                :placeholder="$t('git.invoice_fee')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.due_date"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.due_date")
              }}</label>
              <date-picker
                valueType="format"
                class="w-100"
                v-model="payload.due_date"
                prefix-class="xmx"
                :placeholder="$t(`git.due_date`)"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.invoice_date"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_date")
              }}</label>

              <date-picker
                valueType="format"
                class="w-100"
                v-model="payload.invoice_date"
                prefix-class="xmx"
                :placeholder="$t(`git.invoice_date`)"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.your_reference"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.your_reference")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.your_reference"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.your_reference')"
              />
            </div>
            <div
              class="w-full lg:w-[80%] pb-[10px]"
              v-if="!hidden_fields.our_reference"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.our_reference")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.our_reference"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.our_reference')"
              />
            </div>
          </div>
          <div class="w-full lg:w-[50%]">
            <div
              class="w-full lg:w-[90%] py-[10px]"
              v-if="!hidden_fields.invoice_text"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.invoice_text")
              }}</label>
              <b-form-textarea
                id="textarea"
                v-model="payload.invoice_text"
                :placeholder="$t('git.invoice_text')"
                rows="4"
                cols="7"
                max-rows="7"
              ></b-form-textarea>
            </div>
            <div
              class="w-full lg:w-[90%] pb-[10px]"
              v-if="!hidden_fields.extra_invoice_row_text"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.extra_invoice_row_text")
              }}</label>
              <b-form-input
                type="text"
                v-model="payload.extra_invoice_row_text"
                class="w-[40%] rounded-none"
                :placeholder="$t('git.extra_invoice_row_text')"
              />
            </div>
            <div
              class="w-full lg:w-[90%] pb-[10px]"
              v-if="!hidden_fields.terms_of_payment"
            >
              <label class="text-[15px] font-semibold">{{
                $t("git.payment_terms")
              }}</label>

              <v-select
                label="description"
                :placeholder="$t(`git.payment_terms`)"
                :options="termsOfPayment"
                v-model="selectedTop"
                @option:selected="computeSelect($event)"
                class="resource-selector select-size-sm d-inline-block w-full"
              >
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDueDateValid" class="error-message">
        Förfallodatum måste vara efter fakturadatum
      </div>
      <div class="w-full flex justify-end gap-3">
        <b-button variant="outline-danger" size="md" @click="onClose">
          <span v-text="$t('Message.cancel')" />
        </b-button>
        <b-button v-on:click="onSubmit" variant="primary" size="md">
          <span v-text="loading ? $t('Message.loading') : $t('git.import')" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import PAGES from "@/services/helpers/constant";

export default {
  name: "InvoiceOccassionForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { vSelect, DatePicker },
  data: function () {
    return {
      isDueDateValid: true,
      step: 1,
      programType: "",
      import_data_as: "contract",
      payload: {
        contract_date: "",
        contract_length: 12,
        invoice_interval: 1,
        divide_amount_on_contract_length: true,
        divide_amount_on_number_of_invoices: true,
        number_of_invoices_to_create: 12,
        period_start: "",
        terms_of_payment: {},
        invoice_fee: "",
        due_date: "",
        invoice_date: "",
        your_reference: "",
        our_reference: "",
        extra_invoice_row_text: "",
        invoice_text: "",
        create_contracts: true,
        create_split_invoices: false,
      },
      termsOfPayment: [],
      selectedTop: {
        value: "",
      },
      userTop: {},
      showContract: true,
      showInvoice: true,
      hidden_fields: {
        ...JSON.parse(JSON.stringify(PAGES["golf_related"]["git"])),
      },
    };
  },
  methods: {
    async onSet(invoice_type) {
      this.import_data_as = invoice_type;
      this.step = 2;
      await this.fetchRegister();
      await this.getInvoiceConfig();
    },
    computeSelect: function (event) {
      this.selectedTop = event;
    },
    getContractConfig() {
      this.$useJwt
        .customConfiguration({ URL: "/contract", method: "get" })
        .then((res) => {
          Object.keys(this.payload).map((key) => {
            if (key in res.data.results[0]) {
              this.payload[key] = res.data.results[0][key];
            }
          });
        });
    },
    async fetchRegister() {
      const termsOfPayment = await this.$useJwt.getAllRegisters(
        "termsofpayment"
      );
      this.termsOfPayment = termsOfPayment.data.results.map((el) => {
        return {
          description: el.description,
          value: el.id,
          description_english: el.description_english,
        };
      });
    },
    getInvoiceConfig() {
      this.$useJwt
        .customConfiguration({ URL: "/invoice", method: "get" })
        .then((res) => {
          const result = res.data.results[0];
          if (result.terms_of_payment) {
            let exist = this.termsOfPayment.filter(
              (item) => String(item.value) === String(result.terms_of_payment)
            );
            this.selectedTop = exist.length > 0 ? exist[0] : {};
          }
          this.payload.invoice_fee = result.invoice_fee;
          this.payload.your_reference = result.your_reference;
          this.payload.our_reference = result.our_reference;
          this.payload.extra_invoice_row_text = result.extra_invoice_row_text;
          this.payload.invoice_text = result.invoice_text;
        })
        .catch((err) => {
          console.log("");
        });
    },
    onSubmit() {
      // Check if both invoice date and due date are provided
      if (this.payload.invoice_date && this.payload.due_date) {
        const invoiceDate = new Date(this.payload.invoice_date);
        const dueDate = new Date(this.payload.due_date);

        // Check if due date is after invoice date
        if (dueDate <= invoiceDate) {
          this.isDueDateValid = false;
          return; // Stop the submission process
        } else {
          this.isDueDateValid = true;
        }
      }
      let terms_of_payment = this.selectedTop.value;
      // if ('code' in this.userTop) {
      //   if (this.selectedTop.value === this.userTop.id) {
      //     terms_of_payment = this.userTop.id
      //   }
      // }
      let invoice_form = {
        terms_of_payment: terms_of_payment,
        invoice_fee: this.payload.invoice_fee,
        due_date: this.payload.due_date,
        invoice_date: this.payload.invoice_date,
        your_reference: this.payload.your_reference,
        our_reference: this.payload.our_reference,
        extra_invoice_row_text: this.payload.extra_invoice_row_text,
        invoice_text: this.payload.invoice_text,
      };
      for (let property in invoice_form) {
        if (invoice_form[property] === null) {
          invoice_form[property] = "";
        }
      }
      let contract_form = {
        terms_of_payment: terms_of_payment,
        extra_invoice_row_text: this.payload.extra_invoice_row_text,
        invoice_text: this.payload.invoice_text,
        invoice_date: this.payload.contract_date,
        contract_length: this.payload.contract_length,
        invoice_interval: this.payload.invoice_interval,
        divide_amount_on_contract_length:
          this.payload.divide_amount_on_contract_length,
        period_start: this.payload.period_start,
        your_reference: this.payload.your_reference,
        our_reference: this.payload.our_reference,
        create_contracts: this.payload.create_contracts,
      };
      for (let property in contract_form) {
        if (contract_form[property] === null) {
          contract_form[property] = "";
        }
      }
      let split_invoice_form = {
        terms_of_payment: terms_of_payment,
        extra_invoice_row_text: this.payload.extra_invoice_row_text,
        invoice_text: this.payload.invoice_text,
        invoice_date: this.payload.invoice_date,
        number_of_invoices_to_create: this.payload.number_of_invoices_to_create,
        invoice_interval: this.payload.invoice_interval,
        divide_amount_on_number_of_invoices:
          this.payload.divide_amount_on_number_of_invoices,
        your_reference: this.payload.your_reference,
        our_reference: this.payload.our_reference,
        create_split_invoices: true,
      };
      for (let property in split_invoice_form) {
        if (split_invoice_form[property] === null) {
          split_invoice_form[property] = "";
        }
      }

      if (this.import_data_as === "invoice") {
        this.$emit("submitData", invoice_form);
      } else if (this.import_data_as === "split_invoices") {
        this.$emit("submitData", split_invoice_form);
      } else {
        this.$emit("submitData", contract_form);
      }
    },
    onClose: function () {
      this.$emit("close");
    },
    activateHiddenFields: function () {
      try {
        let hiddenFields = JSON.parse(
          window.localStorage.getItem("hiddenResource")
        ).hidden_fields.golf_related;
        if (!hiddenFields) {
          return false;
        }
        Object.keys(hiddenFields).map((field) => {
          hiddenFields[field].map((k) => {
            this.hidden_fields[k] = true;
          });
        });
      } catch (e) {}
    },
  },
  async mounted() {
    this.programType = JSON.parse(
      window.localStorage.getItem("hiddenResource")
    ).program;
    this.activateHiddenFields();
  },
  computed: {
    top_locale() {
      return this.GET_LOCALE === "sv" ? "description" : "description_english";
    },
    ...mapGetters({
      GET_LOCALE: "appConfig/getLocale",
      CURRENT_USER: "appConfig/currentUser",
    }),
  },
};
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
